.terms-of-use-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.terms-of-use-hero {
  width: 100%;
  height: 1227px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #f9f9f9;
}
.terms-of-use-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.terms-of-use-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.terms-of-use-logo1 {
  width: 64px;
}
.terms-of-use-links1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.terms-of-use-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.terms-of-use-icon1 {
  width: 24px;
  height: 24px;
  display: flex;
}
.terms-of-use-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.terms-of-use-centered {
  width: 100%;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.terms-of-use-left1 {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.terms-of-use-navlink1 {
  display: contents;
}
.terms-of-use-logo2 {
  flex: 1;
  width: var(--dl-size-size-xlarge);
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.terms-of-use-links2 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.terms-of-use-right1 {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.terms-of-use-sign-in {
  margin-right: 20px;
}
.terms-of-use-get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.terms-of-use-get-started:hover {
  background-color: #5228F5;
}
.terms-of-use-text16 {
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
}
.terms-of-use-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.terms-of-use-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.terms-of-use-container3 {
  display: contents;
}
.terms-of-use-content {
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.terms-of-use-text17 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
}
.terms-of-use-text18 {
  color: #0a0a0a;
  font-size: 50px;
}
.terms-of-use-text19 {
  color: #0a0a0a;
  font-size: 50px;
}
.terms-of-use-text21 {
  color: rgb(16, 16, 16);
}
.terms-of-use-text66 {
  display: inline-block;
}
.terms-of-use-text67 {
  display: inline-block;
}
.terms-of-use-text70 {
  display: inline-block;
}
.terms-of-use-text71 {
  display: inline-block;
}
.terms-of-use-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
  background-color: #000000;
}
.terms-of-use-top {
  width: 100%;
  display: flex;
  max-width: 1200px;
  border-color: #3B3B3B;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-bottom-width: 1px;
}
.terms-of-use-left2 {
  width: 33%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.terms-of-use-contact {
  display: flex;
  margin-top: 52px;
  align-items: flex-start;
  flex-direction: column;
}
.terms-of-use-text72 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.terms-of-use-link {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: underline;
}
.terms-of-use-right2 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.terms-of-use-category1 {
  width: 240px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.terms-of-use-text73 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.terms-of-use-links3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-of-use-text74 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.terms-of-use-text75 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.terms-of-use-text76 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.terms-of-use-text77 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.terms-of-use-text78 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.terms-of-use-category2 {
  width: 240px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-of-use-text79 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.terms-of-use-links4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-of-use-text80 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.terms-of-use-text81 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.terms-of-use-navlink2 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.terms-of-use-text82 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.terms-of-use-category3 {
  width: 240px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  flex-direction: column;
}
.terms-of-use-bottom {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  user-select: none;
  flex-direction: column;
}
.terms-of-use-branding {
  width: var(--dl-size-size-large);
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);;
  margin-bottom: var(--dl-space-space-unit);
}
.terms-of-use-text83 {
  color: rgb(104, 104, 104);
  font-size: 14px;
  line-height: 30px;
}
@media(max-width: 991px) {
  .terms-of-use-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .terms-of-use-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .terms-of-use-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .terms-of-use-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .terms-of-use-left1 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .terms-of-use-logo2 {
    width: 64px;
  }
  .terms-of-use-links2 {
    display: none;
  }
  .terms-of-use-sign-in {
    display: none;
  }
  .terms-of-use-get-started {
    display: none;
  }
  .terms-of-use-mobile-menu-button {
    display: flex;
  }
  .terms-of-use-content {
    width: 100%;
    max-width: 1200px;
  }
  .terms-of-use-footer {
    padding: var(--dl-space-space-sixunits);
  }
  .terms-of-use-top {
    flex-direction: column;
  }
  .terms-of-use-left2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .terms-of-use-text72 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .terms-of-use-link {
    text-decoration: underline none;
  }
  .terms-of-use-right2 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .terms-of-use-links3 {
    gap: var(--dl-space-space-unit);
  }
  .terms-of-use-links4 {
    gap: var(--dl-space-space-unit);
  }
  .terms-of-use-branding {
    width: var(--dl-size-size-medium);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .terms-of-use-text83 {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .terms-of-use-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-blue-bg);
  }
  .terms-of-use-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .terms-of-use-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .terms-of-use-left1 {
    margin-left: var(--dl-space-space-unit);
  }
  .terms-of-use-right1 {
    margin-right: var(--dl-space-space-unit);
  }
  .terms-of-use-sign-in {
    display: none;
  }
  .terms-of-use-get-started {
    display: none;
  }
  .terms-of-use-content {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .terms-of-use-text17 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .terms-of-use-text18 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .terms-of-use-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .terms-of-use-hero {
    padding-bottom: 160px;
  }
  .terms-of-use-logo1 {
    margin-left: 0px;
  }
  .terms-of-use-icon1 {
    align-items: center;
    justify-content: center;
  }
  .terms-of-use-footer {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
