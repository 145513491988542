.home-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #5228F5;
}
.home-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-logo1 {
  width: 64px;
}
.home-links1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  display: flex;
}
.home-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-centered {
  width: 100%;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-left1 {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.home-navlink1 {
  display: contents;
}
.home-logo2 {
  flex: 1;
  width: var(--dl-size-size-xlarge);
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-links2 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.home-link10 {
  text-decoration: none;
}
.home-link11 {
  animation-name: none;
  animation-delay: 0s;
  text-decoration: none;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-link12 {
  text-decoration: none;
}
.home-right1 {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-sign-in {
  margin-right: 20px;
  text-decoration: none;
}
.home-get-started10 {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started10:hover {
  background-color: #5228F5;
}
.home-link13 {
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.home-container12 {
  display: contents;
}
.home-content {
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.home-text103 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 80px;
}
.home-text104 {
  color: rgb(238, 233, 254);
  font-size: 20px;
  line-height: 30px;
}
.home-get-started11 {
  color: white;
  cursor: pointer;
  display: flex;
  margin-top: 40px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started11:hover {
  color: black;
  background-color: #ffffff;
}
.home-link14 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.home-dashboard-preview {
  top: 650px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: #633df6;
  border-width: 30px;
  border-radius: 100px;
  flex-direction: column;
  background-color: #633df6;
}
.home-outline {
  display: flex;
  align-items: center;
  border-color: #7350f7;
  border-width: 20px;
  border-radius: 70px;
  flex-direction: column;
  justify-content: center;
  background-color: #7350f7;
}
.home-image1 {
  width: 914px;
  height: 569px;
  z-index: 10;
  user-select: none;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: EFF0F2;
}
.home-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text105 {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
}
.home-text106 {
  font-size: 20px;
  text-align: center;
  line-height: 30px;
}
.home-cards1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-container13 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon12 {
  width: 32px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text107 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text108 {
  line-height: 24px;
}
.home-publish1 {
  height: initial;
}
.home-icon13 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text111 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text112 {
  line-height: 24px;
}
.home-container14 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-icon14 {
  width: 35px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text115 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text116 {
  line-height: 24px;
}
.home-icon15 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text117 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text118 {
  line-height: 24px;
}
.home-container15 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon16 {
  width: 32px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text121 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text122 {
  line-height: 24px;
}
.home-publish2 {
  height: initial;
}
.home-icon17 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text125 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text126 {
  line-height: 24px;
}
.home-container16 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon18 {
  width: 32px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text130 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text131 {
  line-height: 24px;
}
.home-publish3 {
  height: initial;
}
.home-icon19 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text134 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text135 {
  line-height: 24px;
}
.home-container17 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-icon20 {
  width: 35px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text138 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text139 {
  line-height: 24px;
}
.home-icon21 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text142 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text143 {
  line-height: 24px;
}
.home-container18 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-icon22 {
  width: 35px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text146 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text149 {
  line-height: 24px;
}
.home-icon23 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text152 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.home-text153 {
  line-height: 24px;
}
.home-quote-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 160px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: 160px;
  justify-content: center;
  background-color: #000000;
}
.home-quote1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
}
.home-message {
  color: rgb(255, 255, 255);
  font-size: 40px;
  line-height: 52px;
}
.home-author {
  width: 800px;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: row;
}
.home-avatar {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
}
.home-quote2 {
  color: rgb(151, 126, 249);
  width: 100%;
  max-width: 800px;
  margin-left: var(--dl-space-space-unit);
}
.home-text157 {
  font-style: normal;
  font-weight: 600;
}
.home-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-banner-manage {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container19 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text160 {
  text-align: left;
}
.home-text161 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-get-started12 {
  transition: 0.3s;
  margin-bottom: 0;
}
.home-get-started12:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-link15 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.home-image-container1 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cards-image1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}
.home-banner-advanced-analytics1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container2 {
  flex: 1;
  display: flex;
}
.home-cards-image2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-right2 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text166 {
  text-align: left;
}
.home-category10 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text167 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.home-text168 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-category11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text169 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.home-text170 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-category12 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text171 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.home-text174 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-banner1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container20 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text175 {
  color: rgb(82, 40, 245);
  font-style: normal;
  font-weight: 600;
}
.home-text176 {
  text-align: left;
}
.home-text179 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-get-started13 {
  transition: 0.3s;
  margin-bottom: 0;
}
.home-get-started13:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-link16 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.home-image-container3 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image3 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-banner-advanced-analytics2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container2 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container4 {
  flex: 1;
  display: flex;
}
.home-cards-image4 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-right3 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text184 {
  text-align: left;
}
.home-category13 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text185 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.home-text186 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-category14 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text187 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.home-text188 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-category15 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text189 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.home-text192 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-banner2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container21 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left4 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text193 {
  color: rgb(82, 40, 245);
  font-style: normal;
  font-weight: 600;
}
.home-text194 {
  text-align: left;
}
.home-text197 {
  color: rgb(0, 0, 0);
  line-height: 24px;
}
.home-get-started14 {
  transition: 0.3s;
  margin-bottom: 0;
}
.home-get-started14:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-link17 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.home-image-container5 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image5 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-integrations {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sevenunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text203 {
  color: rgb(0, 0, 0);
}
.home-pills-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pills {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.home-container22 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.home-container23 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: center;
}
.home-how-it-works {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #211062;
}
.home-text208 {
  color: rgb(220, 212, 253);
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text209 {
  color: var(--dl-color-gray-white);
  align-self: center;
  text-align: left;
}
.home-centered-container4 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.home-container24 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
}
.home-heading2 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-category16 {
  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #5228F5;
}
.home-headng1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text210 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text211 {
  color: rgb(238, 233, 254);
  line-height: 24px;
}
.home-link18 {
  display: contents;
}
.home-get-started15 {
  margin-bottom: 0px;
  text-decoration: none;
}
.home-get-started15:hover {
  color: black;
  background-color: #ffffff;
}
.home-text215 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-container25 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}
.home-pasted-image1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-row {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.home-category17 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-headng2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text216 {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.home-text217 {
  color: rgb(30, 30, 30);
  line-height: 24px;
}
.home-pasted-image2 {
  width: 100%;
}
.home-category18 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}
.home-headng3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text220 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.home-text221 {
  color: rgb(238, 233, 254);
  line-height: 24px;
}
.home-pasted-image3 {
  width: 100%;
  object-fit: contain;
}
.home-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #5228F5;
}
.home-centered-container5 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text224 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-text225 {
  color: #EEE9FE;
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-selection {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.home-card1 {
  width: 292px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-image2 {
  width: 130px;
  height: 78px;
  object-fit: cover;
}
.home-text226 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.home-text227 {
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}
.home-link19 {
  display: contents;
}
.home-get-started16 {
  align-self: center;
  transition: 0.3s;
  text-decoration: none;
}
.home-get-started16:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text228 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-text229 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points10 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point10 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon24 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text230 {
  color: var(--dl-color-gray-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-bullet-points11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point11 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon26 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text235 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point12 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon28 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text236 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point13 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon30 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text237 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-bullet-points12 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon32 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text238 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-bullet-points13 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point15 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon34 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text239 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon36 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text240 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-card2 {
  width: 304px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-image3 {
  width: 133px;
  height: 78px;
  object-fit: cover;
}
.home-text241 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.home-text242 {
  color: rgb(0, 0, 0);
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
}
.home-link20 {
  display: contents;
}
.home-get-started17 {
  align-self: center;
  transition: 0.3s;
  text-decoration: none;
}
.home-get-started17:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text243 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-text244 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points14 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon38 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text245 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point18 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon40 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text250 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point19 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon42 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text254 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point20 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon44 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text255 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-bullet-points15 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point21 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon46 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text256 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point22 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon48 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text257 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point23 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon50 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text258 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point24 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon52 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text259 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-card3 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: 40px;
  border-radius: 50px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-image4 {
  width: 133px;
  height: 78px;
  object-fit: cover;
}
.home-text260 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.home-text261 {
  color: rgb(0, 0, 0);
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
}
.home-link21 {
  display: contents;
}
.home-get-started18 {
  align-self: center;
  transition: 0.3s;
  text-decoration: none;
}
.home-get-started18:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text262 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-text265 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points16 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point25 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon54 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text266 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point26 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon56 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text271 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point27 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon58 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text275 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point28 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon60 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text276 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-bullet-points17 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point29 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon62 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text277 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point30 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon64 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text278 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-point31 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon66 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text279 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-bullet-points18 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point32 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon68 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text280 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-card4 {
  flex: 1;
  width: 298px;
  height: 486px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  padding-left: 40px;
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-unit);
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: #000000;
}
.home-text281 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.home-text282 {
  color: rgb(255, 255, 255);
  font-weight: 700;
  line-height: 40px;
}
.home-link22 {
  display: contents;
}
.home-get-started19 {
  color: var(--dl-color-gray-black);
  align-self: center;
  transition: 0.3s;
  text-decoration: none;
  background-color: #ffffff;
}
.home-get-started19:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text283 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-text286 {
  color: #ffffff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-bullet-points19 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-point33 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.home-icon70 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.home-text287 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-action-bar {
  width: 100%;
  display: flex;
  padding: 120px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-action {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #5228F5;
}
.home-heading4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
}
.home-text288 {
  color: var(--dl-color-gray-white);
  text-align: left;
}
.home-text289 {
  color: rgb(238, 233, 254);
  line-height: 24px;
}
.home-link23 {
  display: contents;
}
.home-get-started20 {
  margin-bottom: 0px;
  text-decoration: none;
}
.home-text293 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-images {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-image5 {
  width: 100%;
  object-fit: cover;
  user-select: none;
}
.home-text296 {
  display: inline-block;
}
.home-text297 {
  display: inline-block;
  font-family: "Inter";
}
.home-text300 {
  display: inline-block;
  font-family: "Inter";
}
.home-text301 {
  display: inline-block;
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
  background-color: #000000;
}
.home-top {
  width: 100%;
  display: flex;
  max-width: 1200px;
  border-color: #3B3B3B;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-left5 {
  width: 33%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-contact {
  display: flex;
  margin-top: 52px;
  align-items: flex-start;
  flex-direction: column;
}
.home-text302 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-link24 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: underline;
}
.home-right4 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-category19 {
  width: 240px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.home-text303 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-links3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text304 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-text305 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-text306 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-text307 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-text308 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-category20 {
  width: 240px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text309 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-links4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text310 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-text311 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-navlink2 {
  color: rgb(196, 196, 196);
  font-size: 14px;
  text-decoration: none;
}
.home-text312 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-bottom {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  user-select: none;
  flex-direction: column;
}
.home-branding {
  width: var(--dl-size-size-large);
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text313 {
  color: rgb(104, 104, 104);
  font-size: 14px;
  line-height: 30px;
}
@media(max-width: 1200px) {
  .home-text103 {
    color: rgb(255, 255, 255);
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
  }
  .home-text313 {
    color: rgb(104, 104, 104);
    font-size: 14px;
    line-height: 30px;
  }
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .home-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .home-left1 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-logo2 {
    width: 64px;
  }
  .home-links2 {
    display: none;
  }
  .home-sign-in {
    display: none;
  }
  .home-get-started10 {
    display: none;
  }
  .home-mobile-menu-button {
    display: flex;
  }
  .home-content {
    width: 100%;
    max-width: 1200px;
  }
  .home-image1 {
    width: 100%;
  }
  .home-features {
    padding-top: 260px;
  }
  .home-title {
    width: 100%;
    max-width: 1200px;
  }
  .home-text105 {
    font-size: 36px;
    line-height: 40px;
  }
  .home-text106 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .home-cards1 {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-text108 {
    line-height: 21px;
  }
  .home-text112 {
    line-height: 21px;
  }
  .home-text116 {
    line-height: 21px;
  }
  .home-text117 {
    font-size: 24px;
  }
  .home-text118 {
    line-height: 21px;
  }
  .home-text122 {
    line-height: 21px;
  }
  .home-text126 {
    line-height: 21px;
  }
  .home-text131 {
    line-height: 21px;
  }
  .home-text135 {
    line-height: 21px;
  }
  .home-text139 {
    line-height: 21px;
  }
  .home-text142 {
    font-size: 24px;
  }
  .home-text143 {
    line-height: 21px;
  }
  .home-text149 {
    line-height: 21px;
  }
  .home-text152 {
    font-size: 24px;
  }
  .home-text153 {
    line-height: 21px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-sevenunits);
    padding-bottom: var(--dl-space-space-sevenunits);
  }
  .home-message {
    width: 100%;
    max-width: 800px;
  }
  .home-author {
    width: 100%;
    max-width: 800px;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-left2 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-cards-image1 {
    width: 360px;
  }
  .home-banner-advanced-analytics1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-banner-advanced-analytics2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container23 {
    margin-left: 0px;
  }
  .home-category16 {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-headng1 {
    margin-left: var(--dl-space-space-fourunits);
  }
  .home-container25 {
    width: 100%;
  }
  .home-pasted-image1 {
    height: auto;
  }
  .home-headng2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-headng3 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-text225 {
    width: 100%;
    max-width: 600px;
  }
  .home-cards2 {
    display: flex;
    flex-direction: column;
  }
  .home-card1 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text227 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text229 {
    font-size: 16px;
  }
  .home-card2 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text242 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text244 {
    font-size: 16px;
  }
  .home-card3 {
    width: 100%;
    align-items: flex-start;
  }
  .home-card4 {
    width: 100%;
    align-items: flex-start;
  }
  .home-action {
    flex-direction: column;
  }
  .home-heading4 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text288 {
    font-size: 36px;
    line-height: 36px;
  }
  .home-footer {
    padding: var(--dl-space-space-sixunits);
  }
  .home-top {
    flex-direction: column;
  }
  .home-left5 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text302 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link24 {
    text-decoration: underline none;
  }
  .home-right4 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-links3 {
    gap: var(--dl-space-space-unit);
  }
  .home-links4 {
    gap: var(--dl-space-space-unit);
  }
  .home-branding {
    width: var(--dl-size-size-medium);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text313 {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-left1 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-right1 {
    margin-right: var(--dl-space-space-unit);
  }
  .home-sign-in {
    display: none;
  }
  .home-get-started10 {
    display: none;
  }
  .home-content {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-text103 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .home-text104 {
    color: rgb(238, 233, 254);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    line-height: 24px;
  }
  .home-get-started11 {
    margin-top: var(--dl-space-space-twounits);
  }
  .home-dashboard-preview {
    top: 520px;
    width: 90%;
    border-width: 10px;
    border-radius: 45px;
  }
  .home-outline {
    border-width: 10px;
    border-radius: 35px;
  }
  .home-image1 {
    width: 100%;
  }
  .home-features {
    padding-top: 350px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text105 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-container13 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text107 {
    font-size: 24px;
  }
  .home-text108 {
    font-size: 14px;
  }
  .home-publish1 {
    margin-left: 0px;
  }
  .home-text111 {
    font-size: 24px;
  }
  .home-text112 {
    font-size: 14px;
  }
  .home-container14 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze1 {
    margin-right: 0px;
  }
  .home-text115 {
    font-size: 24px;
  }
  .home-text116 {
    font-size: 14px;
  }
  .home-text118 {
    font-size: 14px;
  }
  .home-container15 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text121 {
    font-size: 24px;
  }
  .home-text122 {
    font-size: 14px;
  }
  .home-publish2 {
    margin-left: 0px;
  }
  .home-text125 {
    font-size: 24px;
  }
  .home-text126 {
    font-size: 14px;
  }
  .home-container16 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text130 {
    font-size: 24px;
  }
  .home-text131 {
    font-size: 14px;
  }
  .home-publish3 {
    margin-left: 0px;
  }
  .home-text134 {
    font-size: 24px;
  }
  .home-text135 {
    font-size: 14px;
  }
  .home-container17 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze2 {
    margin-right: 0px;
  }
  .home-text138 {
    font-size: 24px;
  }
  .home-text139 {
    font-size: 14px;
  }
  .home-text143 {
    font-size: 14px;
  }
  .home-container18 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze3 {
    margin-right: 0px;
  }
  .home-text146 {
    font-size: 24px;
  }
  .home-text149 {
    font-size: 14px;
  }
  .home-text153 {
    font-size: 14px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-message {
    font-size: 24px;
    line-height: 31px;
  }
  .home-author {
    width: 100%;
  }
  .home-avatar {
    width: 40px;
    height: 40px;
  }
  .home-quote2 {
    font-size: 14px;
    max-width: 800px;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container19 {
    flex-direction: column;
  }
  .home-left2 {
    margin-right: 0px;
  }
  .home-text160 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text161 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text162 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-link15 {
    font-size: 16px;
  }
  .home-image-container1 {
    justify-content: center;
  }
  .home-cards-image1 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-banner-advanced-analytics1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container1 {
    flex-direction: column-reverse;
  }
  .home-right2 {
    margin-left: 0px;
  }
  .home-text166 {
    font-size: 28px;
    text-align: left;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text167 {
    font-size: 20px;
  }
  .home-text168 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text169 {
    font-size: 20px;
  }
  .home-text170 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text171 {
    font-size: 20px;
  }
  .home-text174 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container20 {
    flex-direction: column;
  }
  .home-left3 {
    margin-right: 0px;
  }
  .home-banner-advanced-analytics2 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container2 {
    flex-direction: column-reverse;
  }
  .home-right3 {
    margin-left: 0px;
  }
  .home-text184 {
    font-size: 28px;
    text-align: left;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text185 {
    font-size: 20px;
  }
  .home-text186 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text187 {
    font-size: 20px;
  }
  .home-text188 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text189 {
    font-size: 20px;
  }
  .home-text192 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container21 {
    flex-direction: column;
  }
  .home-left4 {
    margin-right: 0px;
  }
  .home-integrations {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text203 {
    line-height: 24px;
  }
  .home-text204 {
    line-height: 24px;
  }
  .home-pills {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text209 {
    margin-bottom: 0px;
  }
  .home-heading2 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-headng1 {
    padding-bottom: 0px;
  }
  .home-get-started15 {
    display: none;
  }
  .home-container25 {
    width: 100%;
  }
  .home-row {
    height: auto;
    flex-direction: column;
  }
  .home-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-card1 {
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text226 {
    font-size: 28px;
  }
  .home-card2 {
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text241 {
    font-size: 28px;
  }
  .home-card3 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text260 {
    font-size: 28px;
  }
  .home-card4 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text281 {
    font-size: 28px;
  }
  .home-action-bar {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-action {
    align-items: center;
  }
  .home-heading4 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text288 {
    font-size: 28px;
    text-align: left;
  }
  .home-text293 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-images {
    justify-content: center;
  }
  .home-image5 {
    width: 100%;
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-bottom: 160px;
  }
  .home-logo1 {
    margin-left: 0px;
  }
  .home-icon10 {
    align-items: center;
    justify-content: center;
  }
  .home-text104 {
    color: #EEE9FE;
  }
  .home-get-started11 {
    width: 100%;
  }
  .home-dashboard-preview {
    top: 500px;
  }
  .home-features {
    padding-top: 200px;
  }
  .home-cards1 {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container13 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container14 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze1 {
    margin-bottom: 0px;
  }
  .home-container15 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container16 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container17 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze2 {
    margin-bottom: 0px;
  }
  .home-container18 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze3 {
    margin-bottom: 0px;
  }
  .home-text161 {
    line-height: 24px;
  }
  .home-text162 {
    line-height: 24px;
  }
  .home-cards-image1 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-category16 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-get-started19 {
    background-color: var(--dl-color-gray-white);
  }
  .home-action-bar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
